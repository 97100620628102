import React from "react";
import { useParams } from "react-router-dom";

import UrlBitApiClient from '../utils/urlBitApiClient';

export default function LinkRedirect() {
    const apiClient = new UrlBitApiClient();
    let params = useParams();
    let urlRedirect = "";
    apiClient.getLink(params.linkId).then(response => {
        urlRedirect = response.url;
        // PUT API NOT READY YET
        // apiClient.updateLink(response.linkId,
        //     response.url,
        //     response.title,
        //     response.description,
        //     response.expiresAt,
        //     response.clicks + 1)
        console.log('Redirecting to: ' + urlRedirect);
        window.location.replace(urlRedirect);
    })
    .catch(error => {
        console.log('ERROR! ' + error);
    });

    return null;
  }