import { border } from '@mui/system';
import React from 'react';

export default class AdComponent extends React.Component {
    googleInit = null;
    componentDidMount () {
        const timeout = 200;
        this.googleInit = setTimeout(() => {
            if (typeof window !== 'undefined'){
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
        }, timeout);
    }
    componentWillUnmount() {
        if (this.googleInit) clearTimeout(this.googleInit);
    }
    render () {
        return (
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-8333143281267202"
                data-ad-slot="5792611792"
                data-ad-format="auto"
                data-adtest = {(process.env.NODE_ENV == 'production') ? 'off' : 'on'}
                data-full-width-responsive="true" />
        );
    }

}
  