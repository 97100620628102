import React from "react";
import Typography from "@mui/material/Typography";


export default function About() {
    return (
      <div>
          <Typography variant="h5">What is a URL shortener?</Typography>
          <Typography variant="body2">
            <br/>
            URLbit's URL shortener allows you to take long URLs and remake them into friendlier tiny links.
            You may have come across links that you'd like to share with followers, customers, friends, or family that are extremely verbose and ugly. Our URL shortener is perfect for you!
          </Typography>
          <br/>
          <Typography variant="h5">About Me</Typography>
          <Typography variant="body2">
            <br/>
            <b>Howdy!</b> Thank you for finding my web page. I am an independent developer, fueled with curiosity and passionate about technology!
            Please visit <a href="http://turtlefresh.co" target="_blank">turtlefresh.co</a> to find a list of apps I have developed and / or am in the process of developing.
            If you have any questions please visit my Contact page.
          </Typography>
      </div>
    );
} 