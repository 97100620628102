'use strict';

import { API, Auth } from 'aws-amplify';

class UrlBitApiClient{
    constructor() {
    }

    async signRequest(request) {
        await Auth.currentSession().then((session) => {
            const token = session.getAccessToken().getJwtToken();
            if (token) {
                if (request['headers']) {
                    request['headers']['Authorization'] = `Bearer ${token}`;
                } else {
                    request['headers'] = {
                        'Authorization': `Bearer ${token}`
                    }
                }
            }
        }).catch((error)=>{
            console.debug(error);
        });
    }
    
    async createLink(linkId, url, domain, title, description, expiresAt = -1) {
        console.debug('Attempting to create link...');
        const path = '/links';
        let body = {
            'url' : url,
            'domain' : domain,
            'title' : title,
            'description' : description
        }
        if (linkId){
            body.linkId = linkId
        }
        if (expiresAt && expiresAt != -1){
            body.expiresAt = expiresAt
        }
        return await this.postData(path, body);
    }

    async getLink(linkId) {
        console.debug('Attempting to get link...');
        const path = '/links/' + linkId;
        return await this.getData(path);
    }

    async updateLink(linkId, url, title, description, expiresAt, clicks) {
        console.debug('Attempting to update link...');
        const path = '/links/' + linkId;
        let body = {
            'url' : url,
            'title' : title,
            'description' : description,
            'expiresAt' : expiresAt,
            'clicks' : clicks
        }
        return await this.putData(path, body);
    }

    async getData(path) {
        const apiName = 'UrlBitApi';
        let apiInit = {};
        await this.signRequest(apiInit);
        return await API.get(apiName, path, apiInit);
    }
    async postData(path, body) {
        const apiName = 'UrlBitApi';
        let apiInit = {
            body: body,
        };
        await this.signRequest(apiInit);
        // console.log(body);
        return await API.post(apiName, path, apiInit);
    }
    async putData(path, body) {
        const apiName = 'UrlBitApi';
        let apiInit = {
            body: body,
        };
        await this.signRequest(apiInit);
        // console.log(body);
        return await API.put(apiName, path, apiInit);
    }
}

export default UrlBitApiClient;



