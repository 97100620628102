import AdComponent from './components/AdComponent';
import Amplify, {} from 'aws-amplify';
import AppBarMenu from './components/AppBarMenu'
import AppRoutes from "./components/AppRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Grid from '@mui/material/Grid'
import LinkRedirect from './components/LinkRedirect';
import React from 'react';

import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
  Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
        {
            name: "UrlBitApi",
            endpoint: process.env.REACT_APP_API_ENDPOINT
        }
    ]
  }
});

export const appendGoogleAdsScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8333143281267202";
  script.async = true;
  script.crossorigin = "anonymous"
  document.body.appendChild(script);
}

const RouteComponent = ({component: Component}) => (
  <div>
    {appendGoogleAdsScript()}
    <Grid container
      spacing={3}>
      <Grid item xs={12} md={12}>
        <AppBarMenu/>
      </Grid>
      <Grid item xs={2} md={3}></Grid>
      <Grid item xs={8} md={6}>
        <Component/>
        <AdComponent/>
      </Grid>
      <Grid item xs={2} md={3}></Grid>
    </Grid>
  </div>
);

const App = () => (
  <BrowserRouter>
    <Routes>
    {AppRoutes.map((prop, key) => (
      <Route key={prop.path} path={prop.path} element={<RouteComponent component={prop.component}/>} />
    ))}
      <Route path="/:linkId" element={<LinkRedirect/>} />
    </Routes>
  </BrowserRouter>
);

export default App;
