import Divider from '@mui/material/Divider';
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import LinkCreateForm from "./LinkCreateForm";
import React from "react";
import MyLinks from './MyLinks';

export default function Home() {
    return (
        <div>
          <LinkCreateForm/>
          <br/>
          <Divider />
          {/* <MyLinks/> */}

          <br/>
          <FrequentlyAskedQuestions/>
        </div>
    );
} 