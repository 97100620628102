import React, {} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert';


import UrlBitApiClient from '../utils/urlBitApiClient';
import CopyTextToClipboardButton from './CopyTextToClipboardButton';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class LinkCreateForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            'url': '',
            'domain': process.env.REACT_APP_DOMAIN,
            'title': '',
            'description': '',
            'disableSubmitButton': false,
            'generatedLink' : '',
            'showGeneratedLink': false,
            'showAlert': false,
            'alertSeverity': '',
            'alertMessage' : ''
        };
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    handleSubmit = async () => {
        if (this.state.url == '') {
            this.setState({ 
                showAlert: true,
                alertSeverity: 'error',
                alertMessage: 'Please enter a url.'
            })
        } else {
            this.setState({ disableSubmitButton: true })
            const apiClient = new UrlBitApiClient();
            apiClient.createLink(
                undefined,
                this.state.url,
                this.state.domain,
                this.state.title,
                this.state.description
            ).then(response => {
                // console.log(response)
                this.setState({
                    generatedLink: response.shortUrl,
                    showGeneratedLink: true,
                    disableSubmitButton: false,
                    showAlert: true,
                    alertSeverity: 'success',
                    alertMessage: 'Successfully created link!'
                });
            })
            .catch(error => {
                console.log('ERROR! ' + error);
                this.setState({ 
                    disableSubmitButton: false,
                    showAlert: true,
                    alertSeverity: 'error',
                    alertMessage: 'Unable to create link. Please try again later.'
                })
            });
        }
    }
    handleClearForm = (event) => {
        this.setState({
            ["url"]: "",
            ["title"]: "",
            ["description"]: ""
        });
    }

    render() {
        return(
            <div>
                <div id="link" name="link">
                    <Stack spacing={3}>
                        <Typography variant="h6" align="center" sx={{fontWeight: 'bold'}}>
                            Create a short link with URLbit
                        </Typography>
                        <Typography align="center">
                                The best free url shortener.
                        </Typography>
                        <TextField
                            id="url"
                            name="url"
                            label="URL"
                            onChange={this.handleInputChange}
                            value={this.state.url}
                            placeholder="URL" 
                            required
                            fullWidth={true}
                            />
                        <TextField
                            id="domain"
                            name="domain"
                            label="Domain"
                            onChange={this.handleInputChange}
                            value={this.state.domain}
                            placeholder="Domain" 
                            required
                            disabled={true}
                            fullWidth={true}
                            />
                        {/* <TextField
                            id="title"
                            name="title"
                            label="Title"
                            onChange={this.handleInputChange}
                            value={this.state.title}
                            placeholder="Title"
                            fullWidth={true} />
                        <TextField
                            name="description"
                            id="description"
                            onChange={this.handleInputChange}
                            value={this.state.description}
                            placeholder="Description"
                            label="Description"
                            fullWidth={true}
                            multiline
                            rows={4} /> */}

                            <Button variant="contained"
                                name="linkSubmit"
                                id="linkSubmit"
                                disabled={this.state.disableSubmitButton}
                                fullWidth={true}
                                onClick={this.handleSubmit}>
                                    Create
                            </Button>
                            {/* <Button variant="contained"
                                name="linkClear"
                                id="linkClear"
                                color='warning'
                                onClick={this.handleClearForm}>
                                    Clear Form
                            </Button> */}
                            <CopyTextToClipboardButton
                                hidden={!this.state.showGeneratedLink}
                                id="generatedLink"
                                name="generatedLink"
                                label="Generated Link"
                                disabled={true}
                                value={this.state.generatedLink} fullWidth={true} />
                    </Stack>
                </div>
                <Snackbar open={this.state.showAlert} onClose={() => this.setState({showAlert:false})} autoHideDuration={6000} >
                    <Alert severity={this.state.alertSeverity} sx={{ width: '100%' }}>
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}
export default LinkCreateForm;