import React from 'react';
import About from './About'
import Contact from './Contact'
// import Privacy from './Privacy'
import Home from './Home'

const AppRoutes = [
    {
      path: '/',
      menuName: 'Home',
      component: Home
    },
    {
      path: '/about',
      menuName: 'About',
      component: About
    },
    {
      path: '/contact',
      menuName: 'Contact',
      component: Contact
    },
    // {
    //   path: '/privacy',
    //   menuName: 'Privacy',
    //   component: Privacy
    // }
  ];
  
  export default AppRoutes;