import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem"; 
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppRoutes from "./AppRoutes";

export default function AppBarMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <Toolbar>

                <Typography variant="h6" flex={1}>
                URLbit
                </Typography>
                <IconButton onClick={handleClick}
                    edge="start"
                    color="inherit"
                    aria-label="menu">
                    <MenuIcon/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    {AppRoutes.map((prop, key) => (
                        <MenuItem key={prop.path} 
                            onClick={handleClose}
                            component={Link}
                            to={prop.path}>
                                {prop.menuName}
                        </MenuItem>
                    ))}
                </Menu>
            </Toolbar>
        </AppBar>
    );
}