import React from "react";
import Typography from "@mui/material/Typography";

export default function Contact() {
    return (
      <div>
        <Typography variant="h5">Contact Me</Typography>
        <Typography variant="body2">
          <br/>
          If you have any support questions please send an email to:
          <br/>
          <a href="mailto:turtlefresh.help@gmail.com">
            turtlefresh.help@gmail.com
          </a>
        </Typography>
      </div>
    );
} 