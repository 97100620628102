import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";

const faqs = [
    {
        question: "What is a URL shortener?",
        answer: "A URL shortener (also known as a link shortener) allows you to take long URLs and transform them into friendlier and readable tiny links. When a user clicks a short link, they will automatically get redirected to the original destination (long link)."
    },
    {
        question: "What are the benefits of a short url?",
        answer: "Short links can increase traffic to your sites and products. They are easier to read and more memorable for your friends, family, customers, and followers. They take up less space for character limited fields (like Tweets) and are easier to share / manage."
    },
    {
        question: "What makes URLbit the best?",
        answer: "Its free, fast, simple, and has an iOS app."
    }
]


export default function FrequentlyAskedQuestions() {
    return (
        <div>
            <Typography variant="h6" align="center">Frequently Asked Questions</Typography>
            <br/>
            {faqs.map((prop, key) => (
                <Accordion key={key}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{fontWeight: 'bold'}}>{prop.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {prop.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
