import { Snackbar } from '@mui/material'
import { Component} from 'react'
import TextField from '@mui/material/TextField';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";


class CopyTextToClipboardButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            setOpen: false
        }
    }

    handleClick = () => {
        navigator.clipboard.writeText(this.props.value);
        this.setState({open:true})
    }
      
    render () {
        return (
            <div hidden={this.props.hidden}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <TextField
                        id={this.props.id}
                        name={this.props.name}
                        label={this.props.label}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        disabled={this.props.disabled}
                        fullWidth={true} />
                    <IconButton onClick={this.handleClick}>
                        <ContentCopyIcon titleAccess="Copy Link"/>
                    </IconButton>
                </Stack>
                <Snackbar
                    open={this.state.open}
                    onClose={() => this.setState({open:false})}
                    autoHideDuration={2000}
                    message="Copied to clipboard"/>
            </div>
        );
    }
};
export default CopyTextToClipboardButton;